<template>
  <div class="conditions">
    <div class="wrapper-content">
      <div class="conditions__top">
        <h2 class="conditions__title">Условия</h2>
        <p class="conditions__desc">
          Помогаем найти деньги в <strong>сложных ситуациях</strong>
        </p>
      </div>
      <ul class="conditions__list conditions-list">
        <li
          v-for="({ title, desc }, index) in conditions"
          :key="index"
          class="conditions-list__item"
        >
          <p class="conditions-list__title">
            {{ title }}
          </p>
          <p class="conditions-list__desc">
            {{ desc }}
          </p>
        </li>
      </ul>
      <p class="conditions__more" @click="getMore">Подробнее об условиях</p>
    </div>
  </div>
</template>

<script>
import scrollTo from "@/helpers/scrollTo";

export default {
  name: "Conditions",
  data() {
    return {
      conditions: [
        {
          title: "Возраст:",
          desc: "от 18 лет",
        },
        {
          title: "Первый займ:",
          desc: "от 0%",
        },
        {
          title: "Территория:",
          desc: "Все регионны России",
        },
      ],
    };
  },
  methods: {
    getMore() {
      scrollTo(".footer");
    },
  },
};
</script>
